<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>Detail</b>
        <span class="pull-right"> </span>
      </h3>
      <hr />
      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <gtTabs>
            <gtTab title="Detail Surat">
              <div class="row">
                <div class="col-md-6 pull-left">
                  <small class="text-muted">Tanggal Surat</small>
                  <h6>
                    {{
                      state.detail.tanggal_surat === ""
                        ? "-"
                        : formatDate(state.detail.tanggal_surat)
                    }}
                  </h6>
                  <small class="text-muted">Pengirim</small>
                  <h6>
                    {{
                      state.detail.senderUnit === ""
                        ? "-"
                        : state.detail.senderUnit
                    }}
                  </h6>
                  <small class="text-muted">Asal Surat</small>
                  <h6>
                    {{
                      state.detail.asalsurat === ""
                        ? "-"
                        : state.detail.asalsurat
                    }}
                  </h6>
                  <small class="text-muted">Perihal</small>
                  <h6>
                    {{
                      state.detail.subject === "" ? "-" : state.detail.subject
                    }}
                  </h6>
                  <small class="text-muted">Agenda</small>
                  <h6>
                    {{
                      state.detail.agenda_number === ""
                        ? "-"
                        : state.detail.agenda_number
                    }}
                  </h6>
                  <small class="text-muted"
                    >Jenis Naskah Dinas / Nama Naskah Dinas
                  </small>
                  <h6>
                    {{
                      state.detail.document_jenis === ""
                        ? "-"
                        : state.detail.document_jenis
                    }}
                  </h6>
                  <small class="text-muted">Sifat Surat</small>
                  <h6>
                    {{
                      state.detail.document_sifat === ""
                        ? "-"
                        : state.detail.document_sifat
                    }}
                  </h6>
                  <small class="text-muted">Kecepatan Surat</small>
                  <h6>
                    {{
                      state.detail.document_kecrat === ""
                        ? "-"
                        : state.detail.document_kecrat
                    }}
                  </h6>
                  <small class="text-muted">Berkas Arsip</small>
                  <h6>
                    {{ state.detail.folder === "" ? "-" : state.detail.folder }}
                  </h6>
                  <small class="text-muted"
                    >Klasifikasi Menurut Arsiparis</small
                  >
                  <h6>
                    {{
                      state.detail.klasifikasi === ""
                        ? "-"
                        : state.detail.klasifikasi
                    }}
                  </h6>
                  <small class="text-muted">Tag</small>
                  <h6>
                    {{ state.detail.tag === "" ? "-" : state.detail.tag }}
                  </h6>
                </div>

                <div class="col-md-6 pull-left">
                  <small class="text-muted">Klasifikasi Dokumen</small>
                  <h6>
                    {{
                      state.detail.clasification_type === ""
                        ? "-"
                        : state.detail.clasification_type
                    }}
                  </h6>
                  <small class="text-muted">Ringkasan Surat</small>
                  <h6><span v-html="state.detail.description"></span></h6>
                  <small class="text-muted">Nomor Dokumen</small>
                  <h6>
                    {{
                      state.detail.nomor_surat === ""
                        ? "-"
                        : state.detail.nomor_surat
                    }}
                  </h6>
                  <small class="text-muted">Halaman / Lampiran</small>
                  <h6>
                    {{ state.detail.pages === "" ? "-" : state.detail.pages
                    }}{{ " / " }}
                    {{ state.detail.jumlah === "" ? "-" : state.detail.jumlah }}
                    {{
                      state.detail.document_lampiran === ""
                        ? "-"
                        : state.detail.document_lampiran
                    }}
                  </h6>
                  <small class="text-muted">Kepada</small>
                  <h6 v-if="state.detail.receiver === ''">-</h6>
                  <h6 v-else>
                    <table>
                      <tr
                        v-for="(rec, index) in state.detail.receiver"
                        :key="rec.value"
                      >
                        <td>{{ index + 1 + ". " + rec.text }}</td>
                        <td style="margin-left: 10px">
                          <i
                            v-if="rec.approval == 2"
                            class="fa fa-envelope"
                            style="
                              cursor: pointer;
                              background: blue;
                              padding: 4px;
                              color: #fff;
                              border-radius: 5px;
                            "
                            @click="showModal(rec)"
                          ></i>
                          <i
                            v-else-if="rec.approval == 1"
                            class="fa fa-envelope"
                            style="
                              cursor: pointer;
                              background: green;
                              padding: 4px;
                              color: #fff;
                              border-radius: 5px;
                            "
                            @click="showModal(rec)"
                          ></i>
                          <i
                            v-else-if="rec.approval == 3"
                            class="fa fa-envelope"
                            style="
                              cursor: pointer;
                              background: red;
                              padding: 4px;
                              color: #fff;
                              border-radius: 5px;
                            "
                            @click="showModal(rec)"
                          ></i>
                        </td>
                      </tr>
                    </table>
                  </h6>
                  <!-- <ModalDefault v-show="isVisible" :title="modalTitle" :content="modalContent" @close="onClose" /> -->
                  <small class="text-muted">Tembusan</small>
                  <h6 v-if="state.detail.receiver2 === ''">-</h6>
                  <h6 v-else>
                    <table>
                      <tr
                        v-for="(rec, index) in state.detail.receiver2"
                        :key="rec.value"
                      >
                        <td>{{ index + 1 + ". " + rec.text }}</td>
                        <td style="margin-left: 10px">
                          <i
                            v-if="rec.approval == 2"
                            class="fa fa-envelope"
                            style="
                              cursor: pointer;
                              background: blue;
                              padding: 4px;
                              color: #fff;
                              border-radius: 5px;
                            "
                            @click="showModal(rec)"
                          ></i>
                          <i
                            v-else-if="rec.approval == 1"
                            class="fa fa-envelope"
                            style="
                              cursor: pointer;
                              background: green;
                              padding: 4px;
                              color: #fff;
                              border-radius: 5px;
                            "
                            @click="showModal(rec)"
                          ></i>
                          <i
                            v-else-if="rec.approval == 3"
                            class="fa fa-envelope"
                            style="
                              cursor: pointer;
                              background: red;
                              padding: 4px;
                              color: #fff;
                              border-radius: 5px;
                            "
                            @click="showModal(rec)"
                          ></i>
                        </td>
                      </tr>
                    </table>
                  </h6>
                </div>

                <hr />
              </div>
            </gtTab>

            <gtTab title="File Terlampir">
              <div class="col-md-12">
                <small class="text-muted">File</small>
                <br />
                <h6
                  v-if="
                    state.detail.files == null ||
                    state.detail.files == undefined
                  "
                >
                  -
                </h6>
                <h6 v-else-if="!state.detail.files.length">-</h6>
                <table v-else class="table table-stripped">
                  <tr>
                    <th>File</th>
                    <th>Pengirim</th>
                    <th>Tanggal</th>
                    <th>Aksi</th>
                  </tr>
                  <tr
                    v-for="file in state.detail.files"
                    v-bind:key="file.filename"
                  >
                    <td>
                      {{ file.filename }}
                    </td>
                    <td>
                      {{
                        state.detail.senderUnit === undefined
                          ? "-"
                          : state.detail.senderUnit
                      }}
                      {{
                        file.nameasal === undefined ? "-" : file.nameasal.name
                      }}
                    </td>
                    <td>
                      {{ formatDate(file.tanggal_surat.date) }}
                      {{ file.tanggal_surat.date }}
                    </td>
                    <td>
                      <a
                        class="btn btn-success text-white"
                        @click="downloadForce(file.url)"
                        title="Download File"
                        ><i class="fa fa-download text-white"></i></a
                      >&nbsp;
                      <a
                        v-if="file.filename.includes('pdf')"
                        @click="prev(file.uuid)"
                        class="btn btn-info text-white"
                        title="View File"
                        ><i class="fa fa-eye text-white"></i
                      ></a>
                      <!-- <a
                        class="btn btn-info text-white"
                        @click="downloadForce(file.url)"
                        title="View File"
                      ><i class="fa fa-eye text-white"></i></a> -->
                    </td>
                  </tr>
                </table>
              </div>
            </gtTab>

            <gtTab title="File Tambahan">
              <div class="col-md-12">
                <small class="text-muted">File</small>
                <br />
                <h6
                  v-if="
                    state.detail.filestambahan == null ||
                    state.detail.filestambahan == undefined
                  "
                >
                  -
                </h6>
                <h6 v-else-if="!state.detail.filestambahan.length">-</h6>
                <table v-else class="table table-stripped">
                  <tr>
                    <th>File</th>
                    <th>Pengirim</th>
                    <th>Tanggal</th>
                    <th>Aksi</th>
                  </tr>
                  <tr
                    v-for="file in state.detail.filestambahan"
                    v-bind:key="file.filename"
                  >
                    <td>
                      {{ file.filename }}
                    </td>
                    <td>
                      {{
                        state.detail.senderUnit === undefined
                          ? "-"
                          : state.detail.senderUnit
                      }}
                      {{
                        file.nameasal === undefined ? "-" : file.nameasal.name
                      }}
                    </td>
                    <td>
                      {{ formatDate(file.tanggal_surat.date) }}
                      {{ file.tanggal_surat.date }}
                    </td>
                    <td>
                      <a
                        class="btn btn-success text-white"
                        @click="downloadForceTambahan(file.url)"
                        title="Download File"
                        ><i class="fa fa-download text-white"></i></a
                      >&nbsp;
                      <a
                        v-if="file.filename.includes('pdf')"
                        @click="prevTambahan(file.uuid)"
                        class="btn btn-info text-white"
                        title="View File"
                        ><i class="fa fa-eye text-white"></i
                      ></a>
                      <!-- <a
                        class="btn btn-info text-white"
                        @click="downloadForce(file.url)"
                        title="View File"
                      ><i class="fa fa-eye text-white"></i></a> -->
                    </td>
                  </tr>
                </table>
              </div>
            </gtTab>
          </gtTabs>
        </div>
      </div>

      <hr />
      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <div></div>
        </div>
      </div>
      <span class="pull-right">
        <button
          type="button"
          @click="showModal({ type: 'cancel' })"
          class="btn btn-inverse"
          title="Kembali"
        >
          Kembali</button
        >&nbsp;
      </span>
    </div>

    <ModalDefault
      v-show="isVisible"
      :title="modalTitle"
      :content="modalContent"
      :action="ActionChangeable"
      :confirmation="isConfirmation"
      @close="onClose"
    />
  </div>
</template>
<script>
import axios from "axios";
import { RotateSquare5 } from "vue-loading-spinner";
import { gtTab, gtTabs } from "@/components/gtTabs";
import TreeView from "@/components/tree-view/index";
import Header from "@/components/pageTitle/index";
var pdfMake = require("pdfmake/build/pdfmake");
var pdfFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  components: {
    RotateSquare5,
    gtTab,
    gtTabs,
    TreeView,
    Header,
  },
  data() {
    return {
      myHTML: "",
      isTouched: false,
      isDisabled: false,
      isModalVisible: false,
      isVisible: false,
      modalTitle: "",
      modalContent: "",
      isConfirmation: false,
      ActionChangeable: null,
      printDispoLoading: false,
    };
  },
  computed: {
    canConfirm() {
      if (this.$store.state.profile.permissions.daftarusulpermanen_confirm) {
        return this.$store.state.profile.permissions.daftarusulpermanen_confirm
          .create;
      }
      return false;
    },
    canDispo() {
      if (this.$store.state.profile.permissions.disposition_in) {
        return this.$store.state.profile.permissions.disposition_in.create;
      }
      return false;
    },
    treeData() {
      return this.$store.state.daftarusulpermanen.detail.tracking !== undefined
        ? this.$store.state.daftarusulpermanen.detail.tracking
        : {};
    },
    items() {
      var data = [];
      if (this.$store.state.daftarusulpermanen.items) {
        for (
          let index = 0;
          index < this.$store.state.daftarusulpermanen.items.items.length;
          index++
        ) {
          let element = this.$store.state.daftarusulpermanen.items.items[index];
          data[index] = {
            id: element.id,
            agenda_number: element.agenda_number,
            nomor_surat: element.nomorsurat,
            subject: element.subject,
            sifat: element.sifat,
            tanggal_diterima: element.tanggal_diterima,
            redaksi: element.redaksi,
            asal_surat: element.asalsurat,
            confirmation: element.confirmation,
            confirm: element.confirm,
            close: element.close,
          };
        }
      }
      return data;
    },

    base() {
      return this.baseUrl;
    },
    state() {
      return this.$store.state.daftarusulpermanen;
    },
    submitted() {
      return this.formatDate(this.state.detail.created_at);
    },
    modified() {
      return this.formatDate(this.state.detail.updated_at);
    },
    tanggalsurat() {
      let text = "-";
      if (this.state.detail != undefined) {
        text = this.formatDate(this.state.detail.tanggal_surat);
      }
      return text;
    },
    tanggalditerima() {
      let text = "-";
      if (this.state.detail != undefined) {
        if (this.state.detail.tanggalditerima == "") {
          text = "";
        } else {
          text = this.formatDate(this.state.detail.tanggalditerima);
        }
      }
      return text;
    },
    tanggalselesai() {
      let text = "-";
      if (this.state.detail != undefined) {
        if (this.state.detail.tanggal_selesai == "") {
          text = "";
        } else {
          text = this.formatDate(this.state.detail.tanggal_selesai);
        }
      }
      return text;
    },

    dataPenerima() {
      var data = [];
      const penerimas = this.state.detail.penerima;
      penerimas.forEach(function (value, i) {
        // data[i] =
        //   {
        //     "text" : "- " + value.text,
        //     "style" : "header4"
        //   };

        data[i] = {
          table: {
            body: [
              [{ text: value.text }, {}],
              // [{}]
            ],
          },
        };
      });
      return data;
    },

    dataIntruksi() {
      var data = [];
      const intruksi = this.state.detail.intruksi;
      intruksi.forEach(function (value, i) {
        // data[i] = {
        //   "text" : "- " + value.text,
        //   "style" : "header4",
        // };
        data[i] = {
          table: {
            body: [
              ["Col1", "Col2", "Col3"],
              ["1", "2", "3"],
              ["1", "2", "3"],
            ],
          },
        };
      });
      return data;
    },

    urlLembarDispo() {
      return (
        this.baseUrl +
        "/daftarusulpermanen/" +
        this.$route.params.id +
        "/disposition_sheet"
      );
    },
  },
  mounted() {
    this.detailLoad();
    // this.getUserDispo(query);
  },
  methods: {
    downloadFileForce(url) {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    detailLoad() {
      const state = {
        loaded: false,
      };
      const user = localStorage.getItem("user");
      this.$store.commit("daftarusulpermanen/STATE", state);
      this.$store.dispatch(
        "daftarusulpermanen/getdaftarusulpermanenfoldersuratById",
        this.$route.params
      );
    },
    onClose() {
      this.isVisible = false;
    },
    showModal(option) {
      this.isVisible = true;
      if (option.type == "cancel") {
        this.modalTitle = "Kembali?";
        this.modalContent = "<p>Apakah anda yakin ingin kembali?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goBack;
      }
    },
    checkExtensi(file) {
      // /[.]/.exec(file) ? /[^.]+$/.exec(file) : undefined;
      // if (
      //   /[^.]+$/.exec(file)[0] === "docx" ||
      //   /[^.]+$/.exec(file)[0] === "doc" ||
      //   /[^.]+$/.exec(file)[0] === "xls"
      // ) {
      if (
        file.includes("docx") ||
        file.includes("doc") ||
        file.includes("xls")
      ) {
        return false;
      } else {
        return true;
      }
    },
    newTab(file) {
      window.open(file, file);
    },
    goDisposisi() {
      this.$store.dispatch(
        "daftarusulpermanen/onDisposisi",
        this.$route.params
      );
    },
    goBack() {
      this.$router.push("/daftarusulpermanen");
    },
    goTandaiAtasan() {
      const data = {
        id: this.$route.params.id,
        flag_atasan: 1,
      };
      // const data = JSON.stringify(payload);
      this.$store.dispatch("daftarusulpermanen/submitTandaiAtasan", data);
    },

    prev(string) {
      let routeData = this.$router.resolve({
        name: "preview.index",
        params: {
          document_type: this.state.detail.document_type,
          id: this.$route.params.id,
          file: string,
        },
      });
      window.open(routeData.href, "_blank");
    },
    prevTambahan(string) {
      let routeData = this.$router.resolve({
        name: "preview.index",
        params: {
          document_type: "surat_diberkaskan",
          id: this.$route.params.id,
          file: string,
        },
      });
      window.open(routeData.href, "_blank");
    },
    downloadDocument(filepath, filename) {
      axios
        .post("daftarusulpermanen/assets/single", { filepath: filepath })
        .then((res) => {
          const linkSource = res.data;
          const downloadLink = document.createElement("a");
          const fileName = filename;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    downloadForce(url) {
      const downloadLink = document.createElement("a");

      downloadLink.href = url;
      downloadLink.target = "_blank";
      downloadLink.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    downloadForceTambahan(url) {
      const downloadLink = document.createElement("a");

      downloadLink.href = url;
      downloadLink.target = "_blank";
      downloadLink.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    downloadDisposition(filepath, filename) {
      axios
        .post("disposition_in/assets/single", { filepath: filepath })
        .then((res) => {
          const linkSource = res.data;
          const downloadLink = document.createElement("a");
          const fileName = filename;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // prev(string) {
    //   let routeData = this.$router.resolve({name:'preview.index',
    //     params:{
    //       document_type:'daftarusulpermanen',
    //       id:this.$route.params.id,
    //       file:string
    //     }
    //     });
    //     window.open(routeData.href, "_blank");
    // },

    goConfirm() {
      this.$store.dispatch("daftarusulpermanen/onConfirm");
    },
    goSelesai() {
      this.$store.dispatch("daftarusulpermanen/onClose");
    },
    goSend() {
      this.$store.dispatch(
        "daftarusulpermanen/goSend",
        JSON.stringify({ document: this.$route.params.id })
      );
    },

    goReturn() {
      this.$store.dispatch("daftarusulpermanen/onReturn");
    },
    approve(val) {
      let payload = {
        document: this.$route.params.id,
        approval: val,
        message: this.myHTML,
      };
      this.$store.dispatch(
        "daftarusulpermanen/approveDoc",
        JSON.stringify(payload)
      );
    },
    onDownloadLembarDispo() {
      var context = this;
      context.printDispoLoading = true;
      const url = context.urlLembarDispo;

      axios
        .get(url, {
          responseType: "blob",
        })
        .then((res) => {
          // https://stackoverflow.com/a/51514660
          let url = window.URL.createObjectURL(res.data);
          let link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
          setTimeout(() => window.URL.revokeObjectURL(url), 100);
          link.remove();

          context.printDispoLoading = false;
        })
        .catch(function (error) {
          // https://github.com/axios/axios#handling-errors

          context.printDispoLoading = false;

          let message = "";
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);

            if (error.response.data.message) {
              message = error.response.data.message;
            } else {
              // TODO: handle expected HTTP status codes with a proper message,
              // otherwise return a generic message.
              message =
                "Terjadi kesalahan, silakan hubungi pengelola aplikasi.";
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);

            message =
              "Terjadi kesalahan di server, silakan coba lagi nanti atau hubungi pengelola aplikasi.";
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message || error);

            message =
              "Terjadi kesalahan, silakan cek koneksi internet Anda atau hubungi pengelola aplikasi.";
          }

          if (message && message.length) {
            const msg = {
              title: "Galat",
              text: message,
              type: "error",
            };
            context.$store.commit("addNotification", msg);
          }
        });
    },
  },
  watch: {
    $route: function (search) {
      this.detailLoad();
    },
  },
};
</script>
